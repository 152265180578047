import React from "react"


import SEO from "../components/ui/seo"
import Layout from "../components/ui/layout"
import Steps from "../components/accueil/steps"

const OffrePage = () => {




    return(
        <Layout>
            <SEO title="Notre approche" description="payez un prix fixe avec immoDefi" />
            <Steps />
        </Layout>
    )
}



export default OffrePage