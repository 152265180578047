import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useStaticQuery, graphql } from "gatsby"

import theme from '../ui/theme.js'


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
        width: "30rem",
        [theme.breakpoints.down('xs')]: {
            width: "10rem",
            padding: "0"
        },
        // [theme.breakpoints.down('sm')]: {
        //     width: "25rem",
        //     padding: "0"
        // }
    },
    heroContainer: {
        backgroundColor: theme.palette.primary.main,
        height: "25rem"
    },
    heading: {
        padding: '2rem',
        width: "100%"
    },
    timelineImage: {
        height: '5rem',
        width: '5rem',
        margin: "0.5rem"
    },
    timeline: {
        [theme.breakpoints.down('xs')]: {
            width: "80%",
            padding: "0"
        }
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
    stepContainer: {
        margin: "6rem",
        width: "80%",
        [theme.breakpoints.down('xs')]: {
            margin: "1rem",
            width: "auto"
        },
        [theme.breakpoints.down('sm')]: {
            width: "auto"
        }
    },
    descriptionContainer: {
        padding: "1rem"
    },
    introContainer: {
        marginLeft: "3rem"
    }
}));


export default function Steps() {
    const classes = useStyles()
    const matchesMD = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const { allStrapiBlockEtapeFonctionnement } = useStaticQuery(graphql`
    query GetFonctionnement {
        allStrapiBlockEtapeFonctionnement {
          nodes {
            ordre
            titre
            image {
              localFile {
                url
              }
            }
            description
          }
        }
      }
    `);

    const stepDescriptions = allStrapiBlockEtapeFonctionnement.nodes.map(item => {
        const step = {
            id: item.ordre,
            title: item.titre,
            description: item.description
        }
        if (item.image) {
            step.image = item.image.localFile.url
        }
        return step
    });

    return (
        <Grid container classes={{ root: classes.stepContainer }}>
            <Grid item container classes={{root: classes.introContainer}} direction="column">
                <Grid item>
                    <Typography variant="h1">IMMODEFI VOUS OFFRE
                        TOUS LES SERVICES DE L’IMMOBILIER
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography style={{ color: theme.palette.common.black, marginBottom: "2rem" }} variant="body1">Pour vous aider dans vos démarches en toute confiance</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h1">NOTRE DEMARCHE VENTE</Typography>
                </Grid>
                <Grid item>
                    <Typography style={{ marginBottom: "2rem" }} variant="body1">Pour vous accompagner avec efficacité</Typography>
                </Grid>
            </Grid>
            {/* <Typography align="left" variant="h4" classes={{ root: classes.heading }}>
                Comment ça marche
                <br />
            </Typography> */}
            <Timeline classes={{ root: classes.timeline }} position={matchesMD ? "left" : "alternate"} align={matchesMD ? "left" : "alternate"}>
                {
                    stepDescriptions.map(step => (
                        <TimelineItem key={step.id}>
                            <TimelineOppositeContent>
                                <Typography variant="body1" color="textSecondary">
                                    {step.title}
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot>
                                    <FolderOpenIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography variant="h6" align="left" component="h1">
                                        {step.title}
                                    </Typography>
                                    <Grid container direction="row">
                                        <Grid item xs={2}>
                                            <img className={classes.timelineImage} src={step.image} alt={step.title} />
                                        </Grid>
                                        <Grid item classes={{ root: classes.descriptionContainer }} xs={10}>
                                            <Typography align="left" variant="body1" color="textSecondary" paragraph>
                                                {step.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    )
                    )
                }
            </Timeline>
            <Grid item container direction="column">
                <Grid item>
                    <Typography variant="h1">NOTRE PLUS BELLE PREUVE D’EFFICACITE</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">La satisfaction de nos clients </Typography>
                </Grid>
            </Grid>
        </Grid>

    )
}